import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {

  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import { CTACard, animateObjects, newContent } from '../../components/Layout';
import SEO from '../../components/seo';
import GiveContentTrans from '../../components/GiveContentTrans';

import Layout from '../../components/Layout';

/* // Create component in same file. We use it just here so keep it in same file. Otherwise export etc,.
function WorkPreview({ work }) {
  return (
    <div>
      <Link to={`/work/${work.slug}`}>
        <h2>
          <span className="mark">{work.title}</span>
        </h2>
      </Link>
     
      <Img fluid={work.image.asset.fluid} alt={work.title} />
    </div>
  );
} */

// markup
// const IndexPage = ({ data }) => {

function AboutPage() {
  const { t } = useTranslation();
  const { languages, originalPath, i18n } = useI18next();

  const langCode = i18n.language;
  return (
    <Layout>
    <main className="open-service-page outer-grid">
      <GiveContentTrans language="et">
        <SEO
          title="Uudiskirjade disain"
          description="Disainime ja loome uudiskirja põhjasid. Kui soovid, et sinu uudiskirjad paistaksid silma siis tasub klikata."
          image="/pagecover.jpg"
        />
      </GiveContentTrans>
      <GiveContentTrans language="en">
        <SEO
          title="Newsletter design"
          description="Elevate your email marketing with our newsletter design services! We craft visually stunning newsletter templates that help you stand out in the inbox."
          image="/pagecover-en-default.jpg"
        />
      </GiveContentTrans>

      <section className="hero">
        <GiveContentTrans language="et">
          <h1>Uudiskirjade disain</h1>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h1>Newsletter Design</h1>
        </GiveContentTrans>

        <div className="page-copy">
          <GiveContentTrans language="et">
            <p>
              Disainime ja loome uudiskirja põhjasid. E-mailide disain võib
              esmapilgul tunduda äärmiselt lihtne, aga paljud ettevõtted ei
              pööra sellele piisavalt tähelepanu. Hea uudiskirja kujundamine on
              kohati keerukam, kui kodulehe loomine.
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <p>
              We design and create newsletter templates. Email design may seem
              extremely simple at first glance, but many companies do not pay
              enough attention to it. Designing a good newsletter can be more
              complex at times than creating a website.
            </p>
          </GiveContentTrans>
        </div>
      </section>

      <div className="img-100">
        <GiveContentTrans language="et">
          <StaticImage
            src="../../assets/uudiskirjad-kaan.jpg"
            alt="Uudiskirja kujundused"
            layout="fullWidth"
          />
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <StaticImage
            src="../../assets/uudiskirjad-kaan.jpg"
            alt="E-mail design"
            layout="fullWidth"
          />
        </GiveContentTrans>
      </div>

      <section className="description-section inner-grid">
        <GiveContentTrans language="et">
          <h2>Uudiskirjade erilisus</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>The uniqueness of newsletters</h2>
        </GiveContentTrans>

        <div className="content-row has-media">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
                Uudiskirja kujundamisel on piiravaid faktoreid, mis teevad töö
                keeruliseks. Tehniliste teadmistega kujundaja oskab piirangute
                vahel navigeerida. Disainides tuleb arvestada e-maili klientide
                poolt tulenevate piirangutega.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
                Designing a newsletter is a challenging task due to several
                limiting factors. A designer with technical expertise can
                navigate these constraints effectively. When designing, it is
                important to consider the limitations imposed by email clients.
              </p>
            </GiveContentTrans>
          </div>
          <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/uudiskiri-2.jpg"
                alt="Asukohapõhine uudiskiri"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/uudiskiri-2.jpg"
                alt="Location based newsletter"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>
        </div>

        <div className="content-row has-media">
          <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/uudiskiri-3.jpg"
                alt="Süsteemiteade"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/uudiskiri-3.jpg"
                alt="System notification e-mail design"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
                Gives oleme kujundanud sadu uudiskirja põhjasid. Oleme
                veendunud, et e-maili turundus pole sotsiaalmeedia ajastul
                kaugeltki mitte iganenud reklaamikanal. E-maili turundus on
                tõusvas trendis ja tulemused, mis selle kaudu on võimalik
                saavutada, ületavad teisi turunduskanaleid.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
                We have designed hundreds of email newsletter templates. We are
                convinced that email marketing is by no means an outdated
                advertising channel in the era of social media. Email marketing
                is a growing trend and the results that can be achieved through
                it surpass other marketing channels.
              </p>
            </GiveContentTrans>
          </div>
        </div>

        <div className="content-row">
          <GiveContentTrans language="et">
            <CTACard
              to="/teenused/kodulehed/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Kodulehekülgede loomine
            </CTACard>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <CTACard
              to="/services/websites/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Website creation
            </CTACard>
          </GiveContentTrans>
        </div>
      </section>
    </main>
    </Layout>
  );
}

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;